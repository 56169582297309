import React from 'react';
import './style.scss';

export const FloatingButtonWrapper = ({ children }) => {
  return (
    <div
      style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        zIndex: 99998,
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
      }}
    >
      {children}
    </div>
  );
};