const URL = `${process.env.REACT_APP_CDN_IMG}/images/icons`;

export const correctIcon = `${URL}/correct.png`;

export const mktzapLogo = `${URL}/logo-mktzap.svg`;
export const mktzapLogoBranco = `${URL}/logotipo-mktzap-branco.svg`;

export const iconMais = `${URL}/icon-mais.svg`;
export const iconInstagram = `${URL}/icon-instagram.svg`;
export const iconMktzap = `${URL}/icon-mktzap.svg`;
export const iconInstagramNovo = `${URL}/icone-instagram-newway.svg`;
export const iconMessenger = `${URL}/icone-messenger-newway.svg`;
export const iconWhatsapp = `${URL}/icone-whatsapp-newway.svg`;
export const iconWhatsAppApi = `${URL}/icone-whatsapp-api-newway.svg`;
export const iconTelegram = `${URL}/icone-telegram-newway.svg`;
export const iconSMS = `${URL}/icone-sms-newway.svg`;
export const iconEmail = `${URL}/icone-email-newway.svg`;
export const iconWebchat = `${URL}/icone-webchat-newway.svg`;
export const iconNovo = `${URL}/icone-novo-newway.svg`;
export const iconPills = `${URL}/pills-icon.svg`;

export const iconeAtendimentoHumanizado = `${URL}/icone-atendimento-humanizado-newway.svg`;
export const iconeDisponibilidade = `${URL}/icone-disponibilidade-24h-newway.svg`;
export const iconeMelhorExperiencia = `${URL}/icone-melhor-experiencia-newway.svg`;
export const iconeMelhoraSac = `${URL}/icone-melhora-sac-newway.svg`;
export const iconeMultiplosAtendentes = `${URL}/icone-multiplos-atendentes-newway.svg`;
export const iconePainelOrganizado = `${URL}/icone-painel-organizado-newway.svg`;

export const imageSupport = `${URL}/suporte-newway.jpg`;
export const imageSupportWebP = `${URL}/webp/suporte-newway.webp`;

export const imageSupportNew = `${URL}/suporte-newway-new.png `;

export const iconeNewwayReduzido = `${URL}/logotipo-newway-reduzido.svg`;
export const badgeMensageria = `${URL}/badge-mensageria-gratis.png`;

export const iconeMktzapTxt = `${URL}/logotipo-mktzap-txt.svg`;
export const iconSplashGold = `${URL}/icon-splash-gold.svg`;
export const iconSplashYllow = `${URL}/icon-splash-yellow.svg`;
export const iconeSmile = `${URL}/icon-smile.svg`;
export const iconeDot = `${URL}/icon-dot.svg`;
export const iconeArrowPlanos = `${URL}/icon-arrow-planos-verde.svg`;

export const iconWhatsappWhiteBorder = `${URL}/whatsapp-white-border.png`;

export const logotiposWppMktzap = `${URL}/logotipos_wpp_mktzap.svg`;
export const whatsappMktzapPrincipal = `${URL}/whatsapp-mktzap-principal.png`;

export const FCdata = `${URL}/FCdata-2.png`;
export const FCIconColorFluxo = `${URL}/FCIcon_Color_Fluxo.png`;
export const FCIconColorUser = `${URL}/FCIcon_Color_User.png`;
export const FCmedalStar = `${URL}/FCmedal-star.png`;
export const FCmessageTick = `${URL}/FCmessage-tick.png`;
export const FCshoppingCart = `${URL}/FCshopping-cart.png`;
export const FCsmsTracking = `${URL}/FCsms-tracking.png`;
export const FCwhatsapp = `${URL}/FCwhatsapp.png`;

export const MKmedal = `${URL}/MKmedal-star.png `;
export const MKchart = `${URL}/MKchart.png`;
export const MKchat = `${URL}/MKchat-bot 1.png`;
export const MKFrame = `${URL}/MKFrame.png`;
export const MKFrame1 = `${URL}/MKFrame-1.png`;
export const MKlamp = `${URL}/MKlamp-on.png`;
export const MKlink = `${URL}/MKlink.png`;
export const MKmessage = `${URL}/MKmessage-tick.png`;

export const LogoCosmos = `${URL}/LogoCosmos.png`;
